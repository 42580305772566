@import "./vars";
$theme-colors: (
  "primary": $tig-blue,
  "success": $queen-1,
  "info": $queen-1,
  "warning": $queen-1,
) !default;

$body-bg: $white;
$body-color: $neutral-7;

.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: 2.5rem !important;
  height: 2.5rem !important;
}
