@import "./vars";

.btn-tig {
  padding: 0.8rem 0.75rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
}

.btn-primary-tig {
  background-color: $tig-blue;
  border-color: $tig-blue;
  color: $white;
  &:hover,
  &:focus,
  &:focus-visible,
  &:checked,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show,
  &.disabled,
  &:disabled {
    background-color: $tig-blue-dark;
    border-color: $tig-blue-dark;
    color: $white;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem $tig-blue-high-light;
  }
}

.btn-outline-secondary-tig {
  background-color: transparent;
  border-color: $tig-grey;
  color: $tig-grey;
  &:hover,
  &.active:focus,
  &.active:focus-visible,
  &:first-child:active,
  &.active,
  :not(.btn-check) + &:active {
    background-color: $tig-grey;
    border-color: $tig-grey;
    color: $white;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus,
  &:focus-visible {
    background-color: transparent;
    border-color: $tig-grey;
    color: $tig-grey;
  }
  &:focus,
  &:focus-visible,
  &.active:focus-visible,
  &:first-child:active:focus-visible {
    box-shadow: 0 0 0 0.25rem $tig-grey-high-light;
  }
}

.btn-errands {
  border: 2px solid $tig-grey-light;
  border-radius: 0.25rem;
  color: $tig-blue;
  font-family: "Aleo", serif;
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 600;
  &:hover {
    border: 2px solid $tig-blue;
    background-color: $white;
    color: $tig-blue;
  }
}
