/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&family=Bitter:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import "./assets/styles/vars";

/* Local Styles*/
@import "./assets/styles/bootstrap-theme";

// @import "ngx-toastr/toastr-bs4-alert";
@import "ngx-toastr/toastr";

@import "./assets/styles/defaults";
@import "./assets/styles/spacing";
@import "./assets/styles/forms";
@import "./assets/styles/buttons";
@import "./assets/styles/modals";
