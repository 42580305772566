@import "./vars";

.modal-title-tig {
  font-family: "Aleo", serif;
  color: $tig-blue;
}

.text-body {
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  color: $tig-grey-dark;
}
