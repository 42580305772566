@import "./vars";
input[type="date"],
input[type="time"] {
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

.form-label-tig {
  text-transform: uppercase;
  color: $tig-grey-dark;
}

.form-read-label-tig {
  text-transform: uppercase;
  font-weight: 400;
  color: $tig-grey;
  font-size: 0.85rem;
}

.input-group-text-tig {
  background-color: $white;
  color: $tig-grey;
  &.is-invalid {
    border-color: $tig-invalid;
  }
}

.form-control-tig,
.form-read-control-tig {
  padding: 0.8rem 0.75rem;
  font-size: 1.2rem;
  font-family: "Urbanist", sans-serif;
  border-radius: 0.25rem;
  &:disabled {
    pointer-events: none;
    opacity: 1;
  }
  &:focus {
    border-color: $tig-grey;
    box-shadow: 0 0 0 0.25rem $tig-grey-light;
  }
}

.form-control-tig {
  color: $tig-grey;
  &:disabled {
    background-color: $white;
  }
}

.form-read-control-tig {
  color: $tig-grey-dark;
  font-weight: 700;
  &:disabled,
  &.disabled {
    background-color: $tig-grey-high-light;
  }
}

.form-check-tig {
  width: 3.2rem;
  height: 3.2rem;
  &:checked {
    background-color: $white;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%237a7a7a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;
    border-color: $tig-grey-light;
  }
  &:focus {
    border-color: $tig-grey;
    box-shadow: 0 0 0 0.25rem $tig-grey-light;
  }
}

.form-check-input-tig {
  width: 1.8rem;
  height: 1.8rem;
  &:checked {
    background-color: $tig-grey;
    border-color: $tig-grey;
  }
  &:focus {
    border-color: $tig-grey;
    box-shadow: 0 0 0 0.25rem $tig-grey-light;
  }
}
