/* * * * * * * * * * * * * * * * * * COLORS * * * * * * * * * * * * * * * * * */
$white: #fff;
$black: #000;
$tig-yellow: #f6db1a;

$tig-blue-high-light: #6e85b9;
$tig-blue-light: #4c6394;
$tig-blue: #2e4591;
$tig-blue-dark: #1c2e6c;
$tig-blue-hight-dark: #0a1647;

$tig-grey-high-light: #eeeeee;
$tig-grey-light: #bab9b9;
$tig-grey: #7a7a7a;
$tig-grey-dark: #4d4d4d;
$tig-grey-hight-dark: #262626;

$tig-light-blue: #3c7ff9;
$tig-aqua: #28d0ff;
$tig-light-gray: #878585;
$tig-text: #0b0b0b;
$background-1: #f2f2f2;
$tig-success: #ff3c3c;

$tig-edited: #15763b;

$neutral-2: #eae9e7;
$neutral-7: #121212;
$caution-2: #9f0000;

$background-2: #f8f4e8;
$neutral-1: #fbfaf5;
$neutral-6: #272727;
$table-text: #292c2a;

$ticket-used: #f93c3c;
$tig-invalid: #dc3545;
$ticket-valid: #72fd01;
$no-ticket: #000;
$ticket-past: #f93c3c;
$ticket-future: #ff9900;

$search-error: #9b2d2d;

/* * * * * * * * * * * * * * * * * * FONTS * * * * * * * * * * * * * * * * * */
$font-grotesk: "Hanken Grotesk", "Helvetica", "Arial", sans-serif;

/* * * * * * * * * * * * * * * * * * BREAKPOINTS * * * * * * * * * * * * * * * * * */

$media-query-xs: 0;
$media-query-sm: 576px;
$media-query-md: 768px;
$media-query-lg: 992px;
$media-query-xl: 1200px;
$media-query-2xl: 1400px;

$max-content-width: 1440px;
$max-content-width-small: 420px;
