/* * * * * * * * * * * * * * * * * * Global Styles * * * * * * * * * * * * * * * * * */
html,
body {
  font-family: $font-grotesk;
  font-weight: 700;
  height: 100%;
  font-size: 16px;
}

/* * * * * * * * * * * * * * * * * * Layout * * * * * * * * * * * * * * * * * */
.d-flex-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
}

#wrapper,
#main-content {
  max-width: 100%;
  min-height: 100%;
  @extend .d-flex-container;
}

#wrapper {
  position: relative;
  overflow: hidden;
  #main-content {
    flex-grow: 1;
    router-outlet + * {
      @extend .d-flex-container;
    }
    .background-container,
    .background-image,
    .overlay-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .background-container {
      z-index: -1;
      display: flex;
      flex-direction: column;
    }
    .background-image {
      background: url("/assets/images/background-page.jpeg") no-repeat center;
      background-size: cover;
    }
    .overlay-image {
      background: url("/assets/images/background-bw.jpeg") no-repeat center;
      background-size: cover;
      opacity: 0.3;
    }
  }
}

header,
main,
footer {
  flex-shrink: 0;
}

/* * * * * * * * * * * * * * * * * * Titles * * * * * * * * * * * * * * * * * */

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-grotesk;
}

.max-content-width {
  max-width: $max-content-width;
  margin: 0 auto;
}

.max-content-width-small {
  max-width: $max-content-width-small;
  margin: 0 auto;
}

/*** HELPERS  ****/

.bg-white {
  background-color: $white;
}

.bg-neutral6 {
  background-color: $neutral-6;
}

.text-white {
  color: $white;
}

.tig-label {
  color: $tig-grey;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  &.tig-label-large {
    font-size: 18px;
  }
}

/*sizes*/

.max-w-260 {
  max-width: 260px;
}

.fs-7 {
  font-size: 0.8rem;
}

.text-blue {
  color: $tig-blue;
}
.text-light-gray {
  color: $tig-grey;
}
.text-text {
  color: $tig-text;
}

.text-success {
  color: $tig-edited;
}
